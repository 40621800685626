<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1 d-flex justify-space-between">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Stock de productos
        </h3>
        <v-btn icon v-if="searchActive" @click="searchActive = false"
          ><v-icon>search</v-icon></v-btn
        >
      </v-col>

      <v-expand-transition>
        <v-col cols="12" v-if="!searchActive" class="py-0">
          <SearchProductShallow typeAction="stock" />
        </v-col>
      </v-expand-transition>

      <v-col cols="12">
        <v-card :class="{ 'pa-2': productData }">
          <app-product-detail
            :productData="productData"
            :currentBranchID="currentBranchID"
          ></app-product-detail>
        </v-card>
      </v-col>
      <v-col cols="12" class="py-1"> </v-col>
    </v-row>
    <v-expand-transition>
      <app-product-stock-fast
        v-if="searchActive"
        :productID="productID"
        :productData="productData"
      >
      </app-product-stock-fast>
    </v-expand-transition>
  </v-container>
</template>
<script>
import SearchProductShallow from "@/components/inventory/SearchProductShallow.vue";
import EventBus from "@/config/bus";
import { mapState } from "vuex";
import ProductDetail from "@/components/products/ProductDetailData";
import ProductStockFast from "@/components/products/ProductStockFast";
import { errorMessage, showMessage } from "@/services/MessageService";
import { database } from "@/config/firebase";

export default {
  data() {
    return {
      searchActive: false,

      productData: null,
      productDatabaseRef: null,
      notFound: false,
      productID: ""
    };
  },
  components: {
    SearchProductShallow,
    AppProductDetail: ProductDetail,
    AppProductStockFast: ProductStockFast
  },
  computed: {
    ...mapState("business", ["currentBranchID"])
  },
  mounted() {
    EventBus.$on("STOCK_SEARCH_RESULT", code => {
      this.productID = code;
      this.getProductData(code);
    });
  },
  beforeDestroy() {
    this.removeListenerDatabase();
  },
  methods: {
    async getProductData(code) {
      try {
        this.productDatabaseRef = await database(`store/${code}`);
        this.productDatabaseRef.on("value", async snapshot => {
          if (snapshot.exists()) {
            this.productData = snapshot.val();
            this.searchActive = true;
          } else {
            this.searchActive = false;
            showMessage({
              message: "El producto que intentas buscar ya no esta disponible",
              color: "success",
              timeout: 2500
            });
          }
        });
      } catch (error) {
        this.searchActive = false;
        errorMessage({
          message:
            "Upss algo salio mal al obtener la información del dashboard. Code:" +
            error
        });
      }
    },
    async removeListenerDatabase() {
      await this.productDatabaseRef.off("value");
      this.productDatabaseRef = null;
    }
  }
};
</script>

<style></style>
