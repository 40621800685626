<template>
  <v-bottom-navigation
    app
    :height="totalInventory != null ? '225' : '260'"
    dark
    background-color="#383838"
    class="stock-container"
  >
    <template v-if="totalInventory != null">
      <v-container>
        <v-row>
          <v-col cols="12" class="py-1">
            <v-select
              v-model="currentBranchSelected"
              :rules="[requiredRule]"
              item-text="branch_office_name"
              item-value="id"
              :items="branchOffice"
              dense
              label="Sucursal"
              hide-details=""
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" class="text-center py-1 d-flex justify-center">
            <div class="mx-1">
              <p class="ma-0 text-h4">
                {{ totalInventory ? totalInventory : 0 }}
              </p>
              <div class="caption" style="margin-top: -8px;">
                <div class="ma-0 caption">
                  {{ totalInventory | filterPartsName }}
                </div>
                <div class="ma-0 caption" style="margin-top: -8px !important">
                  actuales
                </div>
              </div>
            </div>
            <div class="mx-1">
              <p class="ma-0 text-h4">
                {{ inventory ? inventory : 0 }}
              </p>
              <div class="caption" style="margin-top: -8px;">
                <div class="ma-0 caption">
                  {{ inventory | filterPartsName }}
                </div>
                <div class="ma-0 caption" style="margin-top: -8px !important">
                  por añadir
                </div>
              </div>
            </div>
            <div class="mx-1">
              <p class="ma-0 text-h4">
                {{ parseInt(inventory) + parseInt(totalInventory) }}
              </p>
              <div class="caption" style="margin-top: -8px;">
                <div class="ma-0 caption">
                  {{
                    (parseInt(inventory) + parseInt(totalInventory))
                      | filterPartsName
                  }}
                </div>
                <div class="ma-0 caption" style="margin-top: -8px !important">
                  totales
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" class="py-1">
            <div class="d-flex align-center justify-center">
              <v-card
                elevation="2"
                class="mr-2 text-center"
                @click="inventory--"
                width="40"
              >
                <v-icon>remove</v-icon>
              </v-card>
              <div style="width: 80px">
                <v-text-field
                  ref="inventory"
                  class="centered-input"
                  v-model="inventory"
                  hide-details
                  dense
                  outlined
                  :rules="inventoryRules"
                  type="number"
                ></v-text-field>
              </div>
              <v-card
                elevation="2"
                class="ml-2 text-center"
                @click="inventory++"
                width="40"
              >
                <v-icon>add</v-icon>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" class="py-1">
            <v-card
              class="text-center pa-1"
              :class="{ info: inventory > 0, 'white--text': inventory > 0 }"
              @click.prevent="updateInventory"
              :disabled="inventory <= 0"
            >
              <span :class="{ 'grey--text': inventory <= 0 }"
                >Actualizar inventario</span
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <v-container>
        <v-row>
          <template v-if="enableInventory">
            <v-col cols="12" class="py-1">
              <v-select
                v-model="currentBranchSelected"
                :rules="[requiredRule]"
                item-text="branch_office_name"
                item-value="id"
                :items="branchOffice"
                dense
                label="Sucursal"
                hide-details=""
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" class="text-center py-1">
              <p class="ma-0 caption">Inventario actual</p>
              <div class="d-flex align-center justify-center">
                <v-card
                  elevation="2"
                  class="mr-2 text-center"
                  @click="currentInventory--"
                  width="40"
                >
                  <v-icon>remove</v-icon>
                </v-card>
                <div style="width: 80px">
                  <v-text-field
                    ref="currentInventory"
                    class="centered-input"
                    v-model="currentInventory"
                    hide-details
                    dense
                    outlined
                    :rules="inventoryRules"
                    type="number"
                  ></v-text-field>
                </div>
                <v-card
                  elevation="2"
                  class="ml-2 text-center"
                  @click="currentInventory++"
                  width="40"
                >
                  <v-icon>add</v-icon>
                </v-card>
              </div>
            </v-col>
            <v-col cols="12" class="text-center py-1">
              <p class="ma-0 caption">Inventario mínimo</p>
              <div class="d-flex align-center justify-center">
                <v-card
                  elevation="2"
                  class="mr-2 text-center"
                  @click="minInventory--"
                  width="40"
                >
                  <v-icon>remove</v-icon>
                </v-card>
                <div style="width: 80px">
                  <v-text-field
                    ref="minInventory"
                    class="centered-input"
                    v-model="minInventory"
                    hide-details
                    dense
                    outlined
                    :rules="inventoryRules"
                    type="number"
                  ></v-text-field>
                </div>
                <v-card
                  elevation="2"
                  class="ml-2 text-center"
                  @click="minInventory++"
                  width="40"
                >
                  <v-icon>add</v-icon>
                </v-card>
              </div>
            </v-col>
            <v-col cols="12" class="py-1">
              <v-card
                class="text-center pa-1 mt-1"
                :class="{
                  info: currentInventory > 0,
                  'white--text': currentInventory > 0
                }"
                @click.prevent="enableInventoryAction"
                :disabled="currentInventory <= 0"
              >
                <span :class="{ 'grey--text': currentInventory <= 0 }"
                  >Habilitar inventario</span
                >
              </v-card>
            </v-col>
          </template>
          <v-col cols="12" v-else>
            <div class="caption text-center">
              Este producto no cuenta con el inventario habilitado, ¿Desea
              habilitar el inventario para este producto?
            </div>
            <div class="d-flex justify-center mt-2">
              <v-card
                @click="enableInventory = true"
                link
                class="pa-2 text-center"
                width="150"
                dark
              >
                HABILITAR
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-bottom-navigation>
</template>

<script>
import { notSpecialCharacters, required } from "@/services/ValidatorService";
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      inventory: 0,
      inventoryRules: [notSpecialCharacters],
      requiredRule: required,
      totalInventory: 0,
      // Enable inventory
      enableInventory: false,
      currentInventory: 0,
      minInventory: 0,
      currentBranchSelected: null
    };
  },
  async beforeMount() {
    this.currentBranchSelected = this.currentBranchID;
    this.totalInventory = this.getTotalInventoryByBranch();
  },
  methods: {
    ...mapActions("products", [
      "updateProductStock",
      "updateProductStock",
      "enableInventoryProduct"
    ]),
    ...mapActions("business", ["getBranchOfficeData"]),
    getTotalInventoryByBranch() {
      if (this.productData) {
        const brachInventory = this.productData.branch_office[
          this.currentBranchSelected
        ];
        if (!brachInventory.inventory) return null;
        else return brachInventory.inventory.total_inventory;
      } else {
        return 0;
      }
    },
    async updateInventory() {
      if (this.inventory != 0) {
        const totaMoneyToAddPrice =
          parseInt(this.inventory) * parseFloat(this.productData.price);
        const totaMoneyToAdd =
          parseInt(this.inventory) * parseFloat(this.productData.cost_price);
        await this.updateProductStock({
          product_id: this.productID,
          total_to_add: this.inventory,
          total_money_to_add:
            Math.round((totaMoneyToAdd + Number.EPSILON) * 100) / 100,
          total_money_to_add_price:
            Math.round((totaMoneyToAddPrice + Number.EPSILON) * 100) / 100
        });
        this.inventory = 0;
        this.totalInventory = this.getTotalInventoryByBranch();
      }
    },
    async enableInventoryAction() {
      const totaMoneyToAddPrice =
        parseInt(this.currentInventory) * parseFloat(this.productData.price);
      const totaMoneyToAdd =
        parseInt(this.currentInventory) *
        parseFloat(this.productData.cost_price);

      const branchOfficeInventory = {};
      await this.getBranchOfficeData();
      for (const branch of this.branchOffice) {
        if (branch.id === this.currentBranchSelected) {
          branchOfficeInventory[branch.id] = {
            inventory: {
              total_inventory: parseInt(this.currentInventory),
              min_inventory: parseInt(this.minInventory ? this.minInventory : 0)
            },
            metadata: {
              last_updated: moment().unix()
            },
            name: branch.branch_office_name
          };
        } else {
          branchOfficeInventory[branch.id] = {
            inventory: {
              total_inventory: 0,
              min_inventory: parseInt(this.minInventory ? this.minInventory : 0)
            },
            metadata: {
              last_updated: moment().unix()
            },
            name: branch.branch_office_name
          };
        }
      }
      await this.enableInventoryProduct({
        productID: this.productID,
        branchOfficeInventory: branchOfficeInventory,

        totalInventory: parseInt(this.currentInventory),
        totalMoney: totaMoneyToAdd,
        totalMoneyPrice: totaMoneyToAddPrice,
        currentBranch: this.currentBranchSelected
      });
      this.totalInventory = this.getTotalInventoryByBranch();
    }
  },
  watch: {
    currentBranchSelected(newValue) {
      if (newValue) {
        this.totalInventory = this.getTotalInventoryByBranch();
      }
    }
  },
  computed: {
    ...mapState("business", ["branchOffice", "currentBranchID"])
  },
  filters: {
    filterPartsName(inventory) {
      if (inventory == 0) return " Piezas";
      if (inventory > 1) return " Piezas";
      else return " Pieza";
    }
  },
  props: {
    productID: {
      required: true
    },
    productData: {
      required: true
    }
  }
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
<style>
.stock-container {
  box-shadow: 0px -1px 8px #606060 !important;
}
</style>
