<template>
  <v-row v-if="productData">
    <v-col cols="12">
      <v-img
        :src="
          productData.photoURL
            ? productData.photoURL
            : require('@/assets/ssencial-logo.svg')
        "
        height="140"
        contain
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-card>
        <v-container class="py-1">
          <div class="caption text--secondary">
            Código
          </div>
          <div class="text--secondary font-weight-bold">
            {{ productData.barcode }}
          </div>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-card>
        <v-container class="py-1">
          <div class="ma-0 caption text--secondary">Nombre</div>
          <div class="ma-0">{{ productData.name_product }}</div>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-card>
        <v-container class="py-1">
          <div class="ma-0 caption text--secondary">Descripción</div>
          <div class="ma-0">
            {{ productData.description }}
          </div>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="6" class="py-1">
      <v-card>
        <v-container class="py-1">
          <p class="ma-0 caption text--secondary">Precio al publico</p>
          <div class="ma-0">$ {{ productData.price }}</div>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="6" class="py-1">
      <v-card>
        <v-container class="py-1">
          <p class="ma-0 caption text--secondary">Precio costo</p>
          <div class="ma-0">$ {{ productData.cost_price }}</div>
        </v-container>
      </v-card>
    </v-col>
    <template
      v-if="getInventoryData(productData.branch_office, 'products') != null"
    >
      <v-col cols="6" class="py-1">
        <v-card
          :class="{
            'error lighten-1': hasLowInventory(productData.branch_office),
            'white--text': hasLowInventory(productData.branch_office),
            'pulse-on': hasLowInventory(productData.branch_office)
          }"
        >
          <v-container class="py-1">
            <p class="ma-0 caption text--secondary">Inventario Actual</p>
            <div class="ma-0">
              {{ getInventoryData(productData.branch_office, "products") }}
              <span class="caption">{{
                getInventoryData(productData.branch_office, "products")
                  | isInventoried
              }}</span>
            </div>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" class="py-1">
        <v-card>
          <v-container class="py-1">
            <p class="ma-0 caption text--secondary">Inventario mínimo</p>
            <div class="ma-0">
              {{ getInventoryData(productData.branch_office, "min_inventory") }}
              <span class="caption">{{
                getInventoryData(productData.branch_office, "min_inventory")
                  | isInventoried
              }}</span>
            </div>
          </v-container>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="py-1"
        v-if="getObjectSize(productData.branch_office) > 1"
      >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="px-3">
              Inventario en todas las sucursales
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <template
                v-for="(branch, key, index) in productData.branch_office"
              >
                <v-card
                  :key="index + '-branch'"
                  outlined
                  class="px-2 py-1"
                  v-if="key != currentBranchID"
                >
                  <p class="text-center mb-0">{{ branch.name }}</p>
                  <div class="d-flex justify-space-between align-center">
                    <p class="ma-0 caption">
                      Inventario actual:
                    </p>
                    <p class="ma-0 text-subtitle-1">
                      {{ branch.inventory.total_inventory }}
                      <span class="caption">{{
                        branch.inventory.total_inventory | isInventoried
                      }}</span>
                    </p>
                  </div>
                </v-card>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </template>
    <v-col cols="12" class="py-1" v-if="productData.wholesale">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="px-3">
            Listado de precios por mayoreo
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex justify-space-between mb-1">
              <p class="ma-0 caption text--disabled">Precio de Mayoreo</p>
              <p class="ma-0 caption text--disabled">Min. Mayoreo</p>
            </div>
            <template v-for="(wholesale, index) in productData.wholesale">
              <div
                :key="index + '-wholesale-detail'"
                class="d-flex justify-space-between mb-1"
                v-if="productData.wholesale"
              >
                <p
                  class="ma-0"
                  v-html="
                    wholesaleDiscount(
                      wholesale.wholesale_price,
                      productData.price
                    )
                  "
                ></p>
                <p class="ma-0">
                  <span class="font-weight-medium">{{
                    wholesale.minimum_products
                  }}</span>
                  <span class="caption">{{
                    wholesale.minimum_products | isInventoried
                  }}</span>
                </p>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12" class="text-center">
      <p class="ma-0 caption text--disabled">
        Ultima modificación:
        {{ getLastUpdated(productData.branch_office) }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
export default {
  methods: {
    wholesaleDiscount(wholesale_price, price) {
      const totalPrice = parseFloat(price);
      const wholesalePriceByUnit = parseFloat(wholesale_price);
      const wholesalePrice = wholesalePriceByUnit * 100;
      const discount = 100 - wholesalePrice / totalPrice;
      return `$ ${wholesalePriceByUnit} <span class="caption text--secondary ml-2"> ${parseFloat(
        discount
      ).toFixed(1)}% Descuento </span> `;
    },
    getInventoryData(branchData, type) {
      if (branchData[this.currentBranchID].inventory) {
        const branchOfficeData = branchData[this.currentBranchID].inventory;
        if (type === "products") {
          return branchOfficeData.total_inventory;
        }
        if (type === "min_inventory") {
          return branchOfficeData.min_inventory;
        }
        return null;
      }
      return null;
    },
    getLastUpdated(branchData) {
      if (branchData[this.currentBranchID]) {
        const branchOfficeData = branchData[this.currentBranchID].metadata;
        return moment
          .unix(branchOfficeData.last_updated)
          .format("dddd DD [a las] h:mm a [del] YYYY");
      }
      return "";
    },
    hasLowInventory(branchOfficeData) {
      if (branchOfficeData[this.currentBranchID]) {
        if (branchOfficeData[this.currentBranchID].inventory) {
          const totalInventory =
            branchOfficeData[this.currentBranchID].inventory.total_inventory;
          const minInventory =
            branchOfficeData[this.currentBranchID].inventory.min_inventory;
          if (parseInt(totalInventory) <= parseInt(minInventory)) return true;
          return false;
        }
        return false;
      }
    },
    getObjectSize(obj) {
      return Object.keys(obj).length;
    }
  },
  props: {
    productData: {
      required: true
    },
    currentBranchID: {
      required: true
    }
  },
  filters: {
    isInventoried(inventory) {
      if (inventory != null) {
        if (inventory === 1) return " Pieza";
        else return " Piezas";
      } else {
        return "N/A";
      }
    }
  }
};
</script>

<style scoped>
.pulse-on {
  animation: shadow-pulse 1.5s infinite;
}
.image-back {
  background-color: #f3f3f3;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
</style>
